<template>
  <div class="AddContractdetail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <el-form label-width="150px">
      <el-form-item label="合同模板名称">
        <el-input v-model="ContractName" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="合同条款">
        <WE :content="this.defaultContent" ref="we"></WE>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 180px;margin-bottom:20px" type="primary" @click="AddForm()">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import WE from "@/base_modules/components/ue";
export default {
  components: { WE },
  data() {
    let _this = this;
    return {
      ContractName: "",
      ContractID: "",
      title: "添加合同模板",
      defaultContent: "",
      ContractNamelit: "",
      defaultContentlist: "",
      showHide: false
    };
  },
  activated() {
    this.ContractName = "";
    if (this.$route.params.type == "add") {
      this.$refs.we.clearContent();
    }
  },
  methods: {
    AddForm() {
      if (this.ContractName == "") {
        return this.$message({
          showClose: true,
          message: "合同模板名称不能为空!",
          type: "warning"
        });
      }
      if (this.$refs.we.getContent() == "") {
        return this.$message({
          showClose: true,
          message: "合同条款不能为空!",
          type: "warning"
        });
      }
      let dto = {
        modelName: this.ContractName,
        modelContent: this.$refs.we.getContent()
      };
      this.post("/tenant-service/leaseContractModel/insert", dto, {
        isUseResponse: true
      }).then(res => {
        this.$message({
          showClose: true,
          message: "添加合同模板成功",
          type: "success"
        });
        this.$back();
      });
    }
  }
};
</script>
<style lang="scss">
.AddContractdetail {
  margin: auto;
  position: relative;
  .btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
    cursor: pointer;
  }
  .w-e-toolbar {
    background-color: #f1f1f1;
    border: 1px solid #ccc;
  }
  .detail {
    border: 1px solid #c9d8db;
    padding: 0 10px;
    overflow-y: auto;
    table {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      th {
        border-bottom: 2px solid #ccc;
        text-align: center;
        background-color: #f1f1f1;
      }
      td,
      th {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 3px 5px;
        min-height: 30px;
      }
    }
  }
}
</style>